import React, { useState, useEffect } from "react";
import moment from "moment";
import { Tabs, Tab, Button, Container, NavDropdown } from "react-bootstrap";
import MenuBar from "../MenuBar";
import { BsCheck } from "react-icons/bs";
import axios from "axios";
import { TRAPHISTORY_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";
import NavBar from "../NavBar";
import { useHistory } from "react-router-dom";
import { eventDescriptions } from "../../DeviceEvents";

export const NotificationPage = (props) => {
  let history = useHistory();

  const [traphistories, setTraphistories] = useState([]);
  const [shareddevices, setShareddevices] = useState([]);
  const [actCount, setActCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    getUnread();
    getShareddevices();
    // eslint-disable-next-line
  }, []);

  const getUnread = () => {
    axios
      .post(`${TRAPHISTORY_SERVER}/getunread`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let dataFromDB = data.data.traphistories.reverse();
          let readArray = [];
          let unreadArray = [];
          let checkText = "@" + user.id + ",";
          /*dataFromDB.forEach((item) => {
            if (item.haveread.indexOf(checkText) > -1) {
              readArray.push(item);
            } else {
              unreadArray.push(item);
            }
          });

          let plusArray = unreadArray.concat(readArray);*/

          //let unreadNotificationsCount = dataFromDB.filter(noti => noti.isRead == false).length;
          let unreadNotificationsCount = data.data.unreadCount;

          console.log('UNREAD:', unreadNotificationsCount);

          //setTraphistories(plusArray);
          setTraphistories(dataFromDB);
          //setActCount(unreadArray.length);
          setActCount(unreadNotificationsCount);
        } else {
          setTraphistories([]);
          setActCount(0);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const createDeviceAlerts = () => {
    if (traphistories.length < 1) return "There is no new device activity.";
    let items = [];
    let checkText = "@" + user.id + ",";

    const makeEventText = (event, model) => {
      let eventDescription = "";
      if (event in eventDescriptions) {
        eventDescription = eventDescriptions[event];
      }
      if (model in eventDescriptions) {
        if (event in eventDescriptions[model]) {
          eventDescription = eventDescriptions[model][event];
        }
      }
      return eventDescription;
    };

    for (let i in traphistories) {
      let row = traphistories[i];
      let isOwner = row.device.ownerId === user.id ? true : false;

      // if (i > 4) break;
      if (!row.isRead) {
        items.push(
          <div key={i}>
            <NavDropdown.Item
              onClick={() => {
                onClickDevNoti(row.deviceId, isOwner);
              }}
            >
              <div className="orange_dot"></div>
              <span className="noti_dev">
                {row.device.deviceCode} - {row.device.deviceName}
              </span>{" "}
              <span className="noti_time">
                {makeEventText(row.trapEvent.event, row.device.model)}{" "}
              </span>
              <br />
              <div className="empty_space"></div>{" "}
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      } else {
        items.push(
          <div key={i}>
            <NavDropdown.Item
              onClick={() => {
                onClickDevNoti(row.deviceId, isOwner);
              }}
            >
              <div className="empty_space"></div>
              <span className="noti_dev">
                {row.device.deviceCode} - {row.device.deviceName}
              </span>{" "}
              <span className="noti_time">
                {makeEventText(row.trapEvent.event, row.device.model)}{" "}
              </span>
              <br />
              <div className="empty_space"></div>{" "}
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      }
    }
    return items;
  };

  const getTimeDiff = (from) => {
    let current = new Date();
    let start = moment(current);
    let end = moment(from);

    if (start.diff(end, "minutes") < 60) {
      return start.diff(end, "minutes") + " minutes ago";
    } else if (start.diff(end, "hours") < 24) {
      return start.diff(end, "hours") + " hours ago";
    } else if (start.diff(end, "days") < 30) {
      return start.diff(end, "days") + " days ago";
    } else if (start.diff(end, "months") < 12) {
      return start.diff(end, "months") + " months ago";
    } else {
      return start.diff(end, "years") + " years ago";
    }
  };

  const onClickDevNoti = (deviceid, isOwner) => {
    history.push({
      pathname: "/device/" + deviceid,
      state: {
        devid: deviceid,
        own: isOwner,
      },
    });
  };

  const markAllAsRead = () => {
    axios
      .post(`${TRAPHISTORY_SERVER}/markallasread`, {
        userid: user.id,
      })
      .then(() => {
        getUnread();
      });
  };

  const deleteAll = () => {
    axios
      //.post(`${TRAPHISTORY_SERVER}/removeallbyuserid`, {
      .post(`${TRAPHISTORY_SERVER}/removealltrapeventnotificationsbyuserid`, {
        userid: user.id,
      })
      .then((data) => {
        console.log(data.data);
        if (data.data.success) getUnread();
      });
  };

  const getShareddevices = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getsharededevicebysharedto`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let dataFromDB = data.data.shareddevices;
          let acceptArray = [];
          let pendingArray = [];
          dataFromDB.forEach((item) => {
            if (item.status === "accept") {
              acceptArray.push(item);
            } else {
              pendingArray.push(item);
            }
          });
          let plusArray = pendingArray.concat(acceptArray);

          setShareddevices(plusArray);
          setShareCount(pendingArray.length);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const makeSharedList = () => {
    let items = [];
    if (shareddevices.length < 1) return "There are no new shared devices.";
    for (let i in shareddevices) {
      let row = shareddevices[i];

      // if (i > 4) break;
      if (row.status === "pending") {
        items.push(
          <div key={i}>
            <NavDropdown.Item>
              <span className="noti_dev">
                {row.device.owner.firstName} {row.device.owner.lastName}
              </span>{" "}
              <span className="noti_time">
                shared {row.device.deviceCode} with you.{" "}
              </span>
              <br />
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
              <br />
              <Button
                className="normalButton"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  acceptDevice(row.device.id);
                }}
              >
                Accept
              </Button>
              <Button
                className="cancelButton"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => {
                  declineDevice(row.id);
                }}
              >
                Decline
              </Button>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      } else {
        items.push(
          <div key={i}>
            <NavDropdown.Item>
              <span className="noti_dev">
                {row.device.owner.firstName} {row.device.owner.lastName}
              </span>{" "}
              <span className="noti_time">
                shared {row.device.deviceCode} with you.{" "}
              </span>
              <br />{" "}
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
              <br />
              <span className="noti_time">
                <BsCheck />
                Accepted
              </span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      }
    }
    return items;
  };

  const acceptDevice = (deviceid) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/acceptdevice`, {
        userid: user.id,
        deviceid,
      })
      .then((data) => {
        if (data.data.success) {
          getShareddevices();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const acceptAllDevices = (deviceid) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/acceptalldevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          getShareddevices();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const declineDevice = (deviceid) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/declinedevice`, {
        userid: user.id,
        deviceid,
      })
      .then((data) => {
        if (data.data.success) {
          getShareddevices();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}
          <div className={mainClass}>
            <div className="page_title" style={{ display: "flex" }}>
              Notifications
            </div>
            <div className="page_content">
              <Tabs
                //defaultActiveKey={
                //  !props.location.state.isSharing ? "deviceAlert" : "sharing"
                //}
                transition={false}
              >
                <Tab
                  eventKey="deviceAlert"
                  title={
                    actCount > 0
                      ? "Device alert(" + actCount + ")"
                      : "Device alert"
                  }
                  tabClassName="sub_menus"
                >
                  <div className="sub_content">
                    <div className="sub_title">Device alert</div>
                    <div className="sub_body">
                      <Button
                        className="cancelButton"
                        style={{ margin: "20px 0px" }}
                        onClick={() => {
                          markAllAsRead();
                        }}
                      >
                        Mark all as read
                      </Button>
                      <Button
                        className="cancelButton"
                        style={{ margin: "20px 8px" }}
                        onClick={() => {
                          deleteAll();
                        }}
                      >
                        Delete all
                      </Button>
                      <Container
                        fluid
                        style={{ height: "450px", overflow: "scroll" }}
                      >
                        {createDeviceAlerts()}
                      </Container>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="sharing"
                  title={
                    shareCount > 0 ? "Sharing(" + shareCount + ")" : "Sharing"
                  }
                  tabClassName="sub_menus"
                >
                  <div className="sub_content">
                    <div className="sub_title">Sharing</div>
                    <div className="sub_body">
                      <Button
                        className="cancelButton"
                        style={{ margin: "20px 0px" }}
                        onClick={() => {
                          acceptAllDevices();
                        }}
                      >
                        Accept all
                      </Button>
                      <Container
                        fluid
                        style={{ height: "450px", overflow: "scroll" }}
                      >
                        {makeSharedList()}
                      </Container>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
